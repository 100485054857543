<template>
  <div class="chart-container">
    <chart
      ref="competenceChart"
      class="text-center m-b-20"
      height="100%"
      width="100%"
      :series="options"
      :select-type="selectType"
      @add-section="addSection"
      @remove-section="removeSection"
      @add-level="addDonut"
      @remove-level="removeDonut"
    />
    <div class="text-center m-b-20">
      <el-button type="primary" @click="addSector">
        Добавить блок компетенций
      </el-button>
    </div>
    <div class="text-center">
      <el-radio-group v-model="selectType">
        <!-- <el-radio :label="0">
          Ячейка
        </el-radio> -->
        <el-radio :label="1">
          Блок компетенций
        </el-radio>
        <el-radio :label="2">
          Уровень
        </el-radio>
        <el-radio :label="3">
          Степень
        </el-radio>
        <el-radio :label="4">
          Компетенция
        </el-radio>
      </el-radio-group>
    </div>
  </div>
</template>

<script>
import Chart from "@/components/Competence/HighchartsMy";
import {
  getTemplate,
  addSectorTemplate,
  addSectionTemplate,
  addLevelTemplate,
  deleteSectionTemplate,
  deleteSectorTemplate,
  deleteLevelTemplate,
} from "@/api/competence.js";
export default {
  name: "HighchartsMy",
  components: { Chart },
  data() {
    return {
      department_id: 1,
      selectType: 1,
      countSection: [],
      countSector: [],
      firstDegree: 47,
      secondDegree: 75,
      radiusWhiteArea: 19,
      options: [],
      skill_id: null,
      test: null,
    };
  },
  created() {
    getTemplate().then((response) => {
      this.test = response.data;
      response.data.sectors.sort((a, b) => a.id - b.id);
      for (let i = 0; i < response.data.sectors.length; i++) {
        for (let j = 0; j < response.data.sectors[i].sections.length; j++) {
          this.addSectionWithId(
            response.data.sectors[i].id,
            response.data.sectors[i].color,
            response.data.sectors[i].sections[j],
            response.data.sectors[i].name,
            j < 1 ? true : false
          );
        }
        this.countSector.push(response.data.sectors[i].id);
      }
      this.skill_id = response.data.id;
    });
  },
  methods: {
    addSector() {
      var o = Math.round;
      var r = Math.random;
      var s = 255;
      var color =
        "rgba(" +
        o(r() * s) +
        "," +
        o(r() * s) +
        "," +
        o(r() * s) +
        ",0.5" +
        ")";
      addSectorTemplate({
        name: "Сектор",
        color: color,
        skill_id: this.skill_id,
      }).then((response) => {
        // console.log(response.data)
        var sizeDonut = this.countSection.length + 1;
        var radiusDonut = 360 / sizeDonut;
        var firstSize = 0;
        if (sizeDonut > 1) {
          for (let j = 0; j < this.countSection.length; j++) {
            var levels = this.options.filter(
              (element) => element.custom.section_id === this.countSection[j]
            );
            for (let k = 0; k < levels.length; k++) {
              levels[k].startAngle = firstSize + 1;
              levels[k].endAngle =
                firstSize + radiusDonut * levels[k].data.length - 1;
            }
            firstSize = (firstSize + radiusDonut * levels[0].data.length) % 360;
          }
        }
        const arraySize = [
          ["43.1818181818%", "44%"],
          ["65.2777777777%", "72%"],
          ["75%", "100%"],
        ];
        for (let i = 0; i < 3; i++) {
          this.options.push({
            type: "pie",
            innerSize: arraySize[i][0],
            size: arraySize[i][1],
            showInLegend: i < 1,
            custom: {
              degree_id: response.data.sections[0].degrees[i].id,
              sector_id: response.data.id,
              section_id: response.data.sections[0].id,
              level_id: response.data.sections[0].degrees[i].levels[0].id,
              name: response.data.sections[0].name,
            },
            dataLabels: {
              format: "Секция",
              enabled: i === 2,
            },
            startAngle: firstSize + 1,
            endAngle: firstSize + radiusDonut - 1,
            data: [
              {
                y: 1,
                name: "Сектор",
                color: color,
                custom: {
                  id: response.data.sections[0].degrees[i].levels[0].id,
                  color: color,
                  sector_id: response.data.id,
                  section_id: response.data.sections[0].id,
                  degree_id: response.data.sections[0].degrees[i].id,
                  level_id: response.data.sections[0].degrees[i].levels[0].id,
                  nameSection: response.data.sections[0].name,
                },
              },
            ],
          });
        }
        this.countSection.push(response.data.sections[0].id);
        this.countSector.push(response.data.id);
      });
    },
    addSectionWithId(sector_id, color, section, sectorName, isShowSectorName) {
      var sizeDonut = this.countSection.length + 1;
      var radiusDonut = 360 / sizeDonut;
      var firstSize = 0;
      if (sizeDonut > 1) {
        for (let j = 0; j < this.countSection.length; j++) {
          var levels = this.options.filter(
            (element) => element.custom.section_id === this.countSection[j]
          );
          for (let k = 0; k < levels.length; k++) {
            levels[k].startAngle = firstSize + 1;
            levels[k].endAngle =
              firstSize + radiusDonut * levels[k].data.length - 1;
          }
          firstSize = (firstSize + radiusDonut * levels[0].data.length) % 360;
        }
      }
      const arraySize = [
        ["43.1818181818%", "44%"],
        ["65.2777777777%", "72%"],
        ["75%", "100%"],
      ];
      for (let i = 0; i < 3; i++) {
        section.degrees[i].levels.sort((a, b) => b.id - a.id);
        this.options.push({
          type: "pie",
          innerSize: arraySize[i][0],
          size: arraySize[i][1],
          showInLegend: isShowSectorName && i < 1,
          custom: {
            degree_id: section.degrees[i].id,
            sector_id: sector_id,
            section_id: section.id,
            level_id: section.degrees[i].levels[0].id,
            name: section.name,
          },
          dataLabels: {
            format: section.name,
            enabled: i === 2,
          },
          startAngle: firstSize + 1,
          endAngle: firstSize + radiusDonut - 1,
          data: [
            {
              y: 1,
              name: sectorName,
              color: color,
              custom: {
                id: section.degrees[i].levels[0].id,
                color: color,
                sector_id: sector_id,
                section_id: section.id,
                degree_id: section.degrees[i].id,
                level_id: section.degrees[i].levels[0].id,
                nameSection: section.name,
              },
            },
          ],
        });
        for (let k = 1; k < section.degrees[i].levels.length; k++) {
          this.addDonutWithId(
            section.degrees[i].id,
            color,
            section.degrees[i].levels[k],
            section.name,
            sectorName
          );
        }
      }
      this.countSection.push(section.id);
    },
    addSection(sector_id, color, sectorName) {
      addSectionTemplate({
        name: "Секция",
        description: "",
        sector_id: sector_id,
      }).then((response) => {
        // console.log(response.data)
        var sizeDonut = this.countSection.length + 1;
        var radiusDonut = 360 / sizeDonut;
        var firstSize = 0;
        var levels2 = this.options.filter(
          (element) => element.custom.sector_id === sector_id
        );
        var lastSectionId = levels2[levels2.length - 1].custom.section_id;
        if (sizeDonut > 1) {
          for (let j = 0; j < this.countSection.length; j++) {
            var levels = this.options.filter(
              (element) => element.custom.section_id === this.countSection[j]
            );
            for (let k = 0; k < levels.length; k++) {
              levels[k].startAngle = firstSize + 1;
              levels[k].endAngle =
                firstSize + radiusDonut * levels[k].data.length - 1;
            }
            firstSize = (firstSize + radiusDonut * levels[0].data.length) % 360;
            if (this.countSection[j] === lastSectionId) {
              const arraySize = [
                ["43.1818181818%", "44%"],
                ["65.2777777777%", "72%"],
                ["75%", "100%"],
              ];
              for (let i = 0; i < 3; i++) {
                this.options.push({
                  type: "pie",
                  innerSize: arraySize[i][0],
                  size: arraySize[i][1],
                  custom: {
                    degree_id: response.data.degrees[i].id,
                    sector_id: sector_id,
                    section_id: response.data.id,
                    level_id: response.data.degrees[i].levels[0].id,
                    name: response.data.name,
                  },
                  dataLabels: {
                    format: response.data.name,
                    enabled: i === 2,
                  },
                  startAngle: firstSize + 1,
                  endAngle: firstSize + radiusDonut - 1,
                  data: [
                    {
                      y: 1,
                      name: sectorName,
                      color: color,
                      custom: {
                        id: response.data.degrees[i].levels[0].id,
                        color: color,
                        sector_id: sector_id,
                        section_id: response.data.id,
                        degree_id: response.data.degrees[i].id,
                        level_id: response.data.degrees[i].levels[0].id,
                        nameSection: response.data.name,
                      },
                    },
                  ],
                });
              }
              this.countSection.splice(j + 1, 0, response.data.id);
              j++;
              // this.countSection.push(response.data.id)
              firstSize =
                (firstSize + radiusDonut * levels[0].data.length) % 360;
            }
          }
        }
      });
    },
    removeSection(section_id) {
      var isLastSection = false;
      var isDeleteLegend = false;
      var isShowLegend = false;
      if (this.options.length > 0) {
        var sizeDonut = this.countSection.length - 1;
        var radiusDonut = 360 / sizeDonut;
        var firstSize = 0;
        var levels3 = this.options.filter(
          (element) => element.custom.section_id === section_id
        );
        var sector_id = levels3[0].custom.sector_id;
        var levels4 = levels3.filter(
          (element) => element.showInLegend === true
        );
        if (levels4.length > 0) {
          isDeleteLegend = true;
          isShowLegend = true;
        }
        var levels2 = this.options.filter(
          (element) => element.custom.sector_id === sector_id
        );
        if (levels3.length === levels2.length) {
          isLastSection = true;
        }
        deleteSectionTemplate(section_id).then(() => {
          for (let k = 0; k < this.options.length; k++) {
            if (this.options[k].custom.section_id === section_id) {
              this.options.splice(k, 1);
              // this.$refs.competenceChart.removeSeries(k)
              k--;
            }
          }
          if (isLastSection) {
            this.countSector.splice(
              this.countSector.findIndex((elem) => elem === sector_id),
              1
            );
            deleteSectorTemplate(sector_id);
          }
          this.countSection.splice(
            this.countSection.findIndex((elem) => elem === section_id),
            1
          );
          if (sizeDonut > 1) {
            for (let j = 0; j < this.countSection.length; j++) {
              var levels = this.options.filter(
                (element) => element.custom.section_id === this.countSection[j]
              );
              for (let k = 0; k < levels.length; k++) {
                if (
                  isDeleteLegend &&
                  isShowLegend &&
                  levels[k].custom.sector_id === sector_id
                ) {
                  isShowLegend = false;
                  levels[k].showInLegend = true;
                }
                levels[k].startAngle = firstSize + 1;
                levels[k].endAngle =
                  firstSize + radiusDonut * levels[k].data.length - 1;
              }
              firstSize =
                (firstSize + radiusDonut * levels[0].data.length) % 360;
            }
          }
        });
      }
    },
    addDonutWithId(degree_id, color, level, name, sectorName) {
      var elem;
      var levels = this.options.filter(
        (element) => element.custom.degree_id === degree_id
      );
      var isDataLabels = false;
      if (levels.length > 0) {
        var sizeDonut = levels.length + 1;
        var radiusDonut = 25 / sizeDonut;
        var firstSize =
          parseInt(levels[0].size) > this.firstDegree
            ? parseInt(levels[0].size) > this.secondDegree
              ? this.secondDegree
              : this.firstDegree
            : this.radiusWhiteArea;
        var firstSize2 = firstSize + 25;
        for (let i = 0; i < levels.length; i++) {
          levels[i].size = (firstSize + radiusDonut).toString() + "%";
          levels[i].innerSize =
            ((firstSize * 100) / (firstSize + radiusDonut)).toString() + "%";
          firstSize += radiusDonut;
          if (levels[i].dataLabels.enabled) {
            isDataLabels = true;
            levels[i].dataLabels = {
              enabled: false,
            };
          }
        }
        elem = Object.assign({}, levels[levels.length - 1]);
        var newData = [];
        if (isDataLabels) {
          elem.dataLabels = {
            format: name,
            enabled: true,
          };
        }
        elem.size = firstSize2.toString() + "%";
        elem.innerSize =
          (((firstSize2 - radiusDonut) * 100) / firstSize2).toString() + "%";
        newData.push({
          y: 1,
          name: sectorName,
          color: color,
          custom: {
            id: level.id,
            color: color,
            sector_id: elem.custom.sector_id,
            section_id: elem.custom.section_id,
            degree_id: degree_id,
            level_id: level.id,
            nameSection: name,
          },
        });
        elem.custom = Object.assign({}, elem.custom);
        elem.custom.level_id = level.id;
        elem.data = newData;
        elem.showInLegend = false;
      }
      this.options.push(elem);
      // this.$refs.competenceChart.addSeries(elem)
    },
    addDonut(degree_id, color) {
      var elem;
      var levels = this.options.filter(
        (element) => element.custom.degree_id === degree_id
      );
      var isDataLabels = false;
      if (levels.length > 0) {
        var sizeDonut = levels.length + 1;
        var radiusDonut = 25 / sizeDonut;
        var firstSize =
          parseInt(levels[0].size) > this.firstDegree
            ? parseInt(levels[0].size) > this.secondDegree
              ? this.secondDegree
              : this.firstDegree
            : this.radiusWhiteArea;
        var firstSize2 = firstSize + 25;
        var sizes = [];
        for (let i = 0; i < levels.length; i++) {
          sizes.push({
            size: (firstSize + radiusDonut).toString() + "%",
            innerSize:
              ((firstSize * 100) / (firstSize + radiusDonut)).toString() + "%",
          });
          firstSize += radiusDonut;
        }
        elem = Object.assign({}, levels[0]);
        elem.dataLabels = {
          enabled: false,
        };
        var newData = [];
        sizes.push({
          size: firstSize2.toString() + "%",
          innerSize:
            (((firstSize2 - radiusDonut) * 100) / firstSize2).toString() + "%",
        });
        for (let i = 0; i < levels.length; i++) {
          levels[i].size = sizes[i + 1].size;
          levels[i].innerSize = sizes[i + 1].innerSize;
        }
        elem.size = sizes[0].size;
        elem.innerSize = sizes[0].innerSize;
        let sectorName = elem.data[0].name;
        addLevelTemplate({
          name: "Уровень",
          degree_id: degree_id,
          previousLevel: elem.custom.level_id,
        }).then((response) => {
          newData.push({
            y: 1,
            name: sectorName,
            color: color,
            custom: {
              id: response.data.id,
              color: color,
              sector_id: elem.custom.sector_id,
              section_id: elem.custom.section_id,
              degree_id: degree_id,
              level_id: response.data.id,
              nameSection: elem.custom.name,
            },
          });
          elem.custom = Object.assign({}, elem.custom);
          elem.custom.level_id = response.data.id;
          elem.data = newData;
          elem.showInLegend = false;
          this.options.push(elem);
        });
      }
      // this.$refs.competenceChart.addSeries(elem)
    },
    removeDonut(level_id) {
      if (this.options.length > 0) {
        var isDataLabels = false;
        var isDeleteLegend = false;
        var levels2 = this.options.filter(
          (element) => element.custom.level_id === level_id
        );
        var degree_id = levels2[0].custom.degree_id;
        var levels = this.options.filter(
          (element) => element.custom.degree_id === degree_id
        );
        if (levels.length > 1) {
          var sizeDonut = levels.length - 1;
          var radiusDonut = 25 / sizeDonut;
          var firstSize =
            parseInt(levels[0].size) > this.firstDegree
              ? parseInt(levels[0].size) > this.secondDegree
                ? this.secondDegree
                : this.firstDegree
              : this.radiusWhiteArea;
          deleteLevelTemplate(level_id).then(() => {
            var index = this.options.findIndex(
              (element) => element.custom.level_id === level_id
            );
            if (levels2[0].showInLegend) {
              isDeleteLegend = true;
            }
            if (levels2[0].dataLabels.enabled) {
              isDataLabels = true;
            }
            // this.$refs.competenceChart.removeSeries(index)
            this.options[index] = {
              custom: {
                degree_id: -1,
                level_id: -1,
                name: "",
                section_id: -1,
                sector_id: -1,
              },
              data: [],
              dataLabels: {
                enabled: false,
              },
              endAngle: 0,
              innerSize: "0%",
              size: "0%",
              startAngle: 0,
              type: "pie",
            };
            // this.options.splice(index, 1)
            levels = this.options.filter(
              (element) => element.custom.degree_id === degree_id
            );
            for (let i = 0; i < levels.length; i++) {
              levels[i].size = (firstSize + radiusDonut).toString() + "%";
              levels[i].innerSize =
                ((firstSize * 100) / (firstSize + radiusDonut)).toString() +
                "%";
              firstSize += radiusDonut;
            }
            if (isDeleteLegend) {
              levels[levels.length - 1].showInLegend = true;
            }
            if (isDataLabels) {
              levels[levels.length - 1].dataLabels = {
                enabled: true,
              };
            }
          });
        }
      }
    },
  },
};
</script>

<style scoped>
.chart-container {
  position: relative;
  margin-top: 60px;
}
.m-b-20 {
  margin-bottom: 20px;
}
</style>
